import React from "react";
import resume from "../assets/Jordan_Miller_Resume_Feb-2022.pdf";
import Helmet from "react-helmet";

export default () => {
    if (typeof window !== "undefined") {
        window.location.replace(resume);
    }
    return <>
        <Helmet>
            <html lang="en"/>
            <meta charSet="utf-8"/>
            <title>Resume - Jordan Miller</title>
            <meta name="description" content="Jordan Miller Resume - February 2022"/>
            <link rel="canonical" href="https://jordanmiller.dev/resume"/>
            {/* <meta property="og:image:secure_url" content="https://jordanmiller.dev/ogpImage.jpg"/> */}
            {/* <meta property="og:image" content="http://jordanmiller.dev/ogpImage.jpg" /> */}
            <meta property="og:title" content="Jordan Miller Resume - February 2022"/>
            <meta property="og:description" content="Jordan Miller Resume - February 2022"/>
            <meta property="og:url" content="https://jordanmiller.dev/resume"/>
        </Helmet>
    </>;
};
